import React from 'react';

const ConsumerNewsletterThankYou = () => (
  <div className="flex-[1_1]">
    <div className="min-h-[64px] flex items-center">
      <div className="signup-uppersection">
        <div className="signup-subtitle">Thank you for signing up!</div>
      </div>
    </div>
  </div>
);
export default ConsumerNewsletterThankYou;
