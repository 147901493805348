/**
 * curl --location --request POST 'https://bpi.briteverify.com/api/public/v1/fullverify' \
 *   --header 'Content-Type: application/json' \
 *   --header 'Authorization: ApiKey: api-key-here' \
 *   --data-raw '{
 *       "phone": "135315"
 *  }'
 */

import { EMAIL_REGEX } from 'commons/constants';
import { logCriticalError } from './logger';

const BriteverifyClient = () => {
  const root = {
    apiEndPoint:
      process.env.NEXT_PUBLIC_BRITEVERIFY_API_URL ??
      process.env.REACT_APP_BRITEVERIFY_API_URL,
    apiKey:
      process.env.NEXT_PUBLIC_BRITEVERIFY_API_KEY ??
      process.env.REACT_APP_BRITEVERIFY_API_KEY,
    creditLookupDelay: 10,

    request: async ({ endPoint, method = 'POST', body }) => {
      try {
        const payload = {
          method,
          body: body ? JSON.stringify({ ...body }) : '',
          headers: {
            'Content-Type': 'application/json',
            Authorization:
              process.env.NEXT_PUBLIC_BRITEVERIFY_API_KEY ??
              process.env.REACT_APP_BRITEVERIFY_API_KEY,
          },
        };
        const response = await fetch(endPoint, payload);
        const result = await response.json();
        if (response.status === 200) {
          return result;
        }
        if (!response.ok) {
          logCriticalError(
            'Britverify Error:',
            JSON.stringify({ result, body })
          );
          return result;
        }
        return result;
      } catch (e) {
        logCriticalError('Britverify Error:', { error: e.message, body });
        return {
          apiError: e.message,
        };
      }
    },

    emailLookup: async email => {
      if (!EMAIL_REGEX.test(String(email).toLowerCase())) {
        return {
          valid: false,
          message: 'Email address is invalid.',
        };
      }
      const endPoint = `${root.apiEndPoint}fullverify`;
      const response = await root.request({ endPoint, body: { email } });
      let result = { valid: false };
      // it should not block form if API is not working
      if (response.apiError || response?.errors) {
        result = {
          valid: true,
          message: null,
        };
      } else if (response?.email?.status === 'invalid') {
        result = {
          valid: false,
          message: response.email.error,
        };
      } else if (
        ['valid', 'accept_all', 'unknown'].includes(response?.email?.status)
      ) {
        result = {
          valid: true,
          message: null,
        };
      }
      return result;
    },
  };
  return root;
};

export default BriteverifyClient();
